.header {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    color: darkgreen;
    font-size: 16px;
}
.headerCom {
    display: flex;
    justify-content: space-between;
}
.logo {
    height: 32px;
    margin-top: 7px;
}
h1 {
    margin-left: 0;
}
.icons {
    margin-right: 7px;

}
.home {
    margin-right: 5px;
}
.toolbar {
    background: darkgreen;
}
.efeu {
    font-size: 23px;
    font-weight: bold;
}
.icon {
    margin-right: 2px;
}
.logoutBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    border: none;
    cursor: pointer;
    font-family: inherit;
    text-decoration: underline;
    text-decoration-color: darkgreen;
}
