.loginBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    border: none;
    padding: 5px 5px 5px 5px;
    border-radius: 2px;
    cursor: pointer;
    font-size: 11px;
    font-family: inherit;
    width: 228px;
    min-height: 10px;
    text-decoration: underline;
    text-decoration-color: darkgreen;
}

.login-wrapper form {
    margin-top: 10px;
}

.login-wrapper input {
    width: 200px;
}