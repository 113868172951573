.btn2 {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #000;
    color: #fff;
    border: none;
    padding: 5px 5px 5px 0;
    margin: 2px;
    border-radius: 2px;
    cursor: pointer;
    text-decoration: none;
    font-size: 11px;
    font-family: inherit;
    width: 47.5%;
    min-height: 55px;
    line-height: 3em;
}
.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    border: none;
    padding: 5px 5px 5px 0;
    margin: 2px;
    border-radius: 2px;
    cursor: pointer;
    font-size: 11px;
    font-family: inherit;
    width: 100%;
    min-height: 55px;
    text-decoration: underline;
    text-decoration-color: darkgreen;
}

