.grid-green {
    margin-left: 15%;
    font-size: 17px;
    background: lightgreen;
    border: solid grey;
    border-radius: 4px;
}

.box-grid-green {
    margin-left: 15%;
    font-size: 14px;
    background: lightgreen;
    border: solid grey;
    border-radius: 4px;
}

.box-grid-red {
    margin-left: 15%;
    font-size: 14px;
    background: lightcoral;
    border: solid grey;
    border-radius: 4px;
}

.box-grid-yellow {
    margin-left: 15%;
    font-size: 14px;
    background: lightyellow;
    border: solid grey;
    border-radius: 4px;
}

.grid-yellow {
    font-size: 17px;
    background: lightyellow;
    border: solid grey;
    border-radius: 4px;
}

.grid {
    margin-left: 15%;
    border: black;
    font-size: 17px;
}

.grid-black-empty {
    margin-left: 2.5%;
    border: solid black;
    width: 95%;
    margin-bottom: 1.5%;
    border-radius: 2px;
    text-decoration: none;
}

.gridContainer {
    margin-left: 2.5%;
    border: solid steelblue;
    width: 95%;
    margin-bottom: 1.5%;
    border-radius: 4px;
    background: lightsteelblue;
    text-decoration: none;
}

.gridContainer-box {
    margin-left: 2.5%;
    margin-top: 5%;
    border: solid steelblue;
    width: 95%;
    margin-bottom: 1.5%;
    border-radius: 4px;
    background: lightsteelblue;
    text-decoration: none;
}
.avatar {
    height: 40px;
    width: 40px;
}
.rowC {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.colR{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 10px;
    margin-left: 0.2em;
    margin-top: 10%;
}
.colR-highlighted{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 10px;
    margin-left: 0.2em;
    border: solid crimson;
    border-spacing: 1px;
    margin-top: -10%;
    border-radius: 2px;
}
.mount{
    border: solid steelblue;
    width: 25px;
    height: 25px;
}

.mount-av{
    border: solid steelblue;
    background: green;
    width: 25px;
    height: 25px;
}

.mount-unav{
    border: solid steelblue;
    background: gray;
    width: 25px;
    height: 25px;
}

.box-small-av{
    margin-top: 15%;
    border: solid green;
    background: green;
    margin-left: 12.5%;
    width: 75%;
    font-size: 10px;
    font-weight: bold;
}
.box-large-av{
    border: solid green;
    background: green;
    margin-left: 5%;
    width: 90%;
    font-size: 12px;
    font-weight: bold;
}
.box-small-unav{
    border: solid gray;
    background: gray;
    margin-left: 12.5%;
    margin-top: 15%;
    height: 165%;
    width: 75%;
    font-size: 10px;
    font-weight: bold;
}
.box-large-unav{
    border: solid gray;
    background: gray;
    margin-left: 5%;
    width: 90%;
    font-size: 12px;
    font-weight: bold;
}
.no-box{
    font-size: 7px;
    margin-top: 88.5%;
}
.scanner-frame{
    justify-content: center;
    border: solid;
    horiz-align: right;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
}
