

.grid-gray {
    font-size: 14px;
    background: lightgray;
    border: solid grey;
    border-radius: 4px;
}

.grid {
    margin-left: 5%;
    border: black;
    font-size: 17px;
}
.gridContainer {
    margin-left: .5%;
    border: solid steelblue;
    width: 99%;
    margin-bottom: 1.5%;
    border-radius: 4px;
    background: lightsteelblue;
    text-decoration: none;
}
.smallFont {
    font-size: 11px;
    justify-content: center;
}
.text {
     font-size: 9px;
     margin-top: 5%;
}
.text-bottom {
    font-size: 12px;
    text-align: center;
    margin-left: 5%;
    margin-bottom: 5px;

}
.headline {
    font-size: 12px;
    font-weight: bold;
}
.printButton {
    font-size: 10px;
    padding: 5px 30px;
    border-radius: 2px;
    margin: 5px 0;
    cursor: pointer;
}
.buttonGroup {
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
}
