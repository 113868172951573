@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  color: darkgreen;
  margin-bottom: 20px;
  font-size: 36px;
}

.btn:focus {
  outline: none;
}

.btn:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.task {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.task.reminder {
  border-left: 5px solid green;
}

.task h3 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.form-control-check label {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.form-control-check input {
  -webkit-flex: 2 1;
          flex: 2 1;
  height: 20px;
}

footer {
  margin-top: 30px;
  text-align: center;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    color: darkgreen;
    margin-bottom: 10px;
    font-size: 18px;
}

.logo {
    height: 32px;
    margin: 10px;
}
.containerHeadline {
    margin-left: 2.5%;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2%;
    color: steelblue;
}
.containerHeadlineSmall {
    margin-left: 2.5%;
    text-align: left;
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 2%;
    color: steelblue;
}
.container {
    max-width: 90%;
    margin: 5% auto;
    overflow: auto;
    border: 1px solid steelblue;
    padding: 10px;
    border-radius: 2px;
}

.btn2 {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background: #000;
    color: #fff;
    border: none;
    padding: 5px 5px 5px 0;
    margin: 2px;
    border-radius: 2px;
    cursor: pointer;
    text-decoration: none;
    font-size: 11px;
    font-family: inherit;
    width: 47.5%;
    min-height: 55px;
    line-height: 3em;
}
.btn {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background: #000;
    color: #fff;
    border: none;
    padding: 5px 5px 5px 0;
    margin: 2px;
    border-radius: 2px;
    cursor: pointer;
    font-size: 11px;
    font-family: inherit;
    width: 100%;
    min-height: 55px;
    text-decoration: underline;
    -webkit-text-decoration-color: darkgreen;
            text-decoration-color: darkgreen;
}


.header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    color: darkgreen;
    font-size: 16px;
}
.headerCom {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.logo {
    height: 32px;
    margin-top: 7px;
}
h1 {
    margin-left: 0;
}
.icons {
    margin-right: 7px;

}
.home {
    margin-right: 5px;
}
.toolbar {
    background: darkgreen;
}
.efeu {
    font-size: 23px;
    font-weight: bold;
}
.icon {
    margin-right: 2px;
}
.logoutBtn {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background: #000;
    color: #fff;
    border: none;
    cursor: pointer;
    font-family: inherit;
    text-decoration: underline;
    -webkit-text-decoration-color: darkgreen;
            text-decoration-color: darkgreen;
}

.grid-green {
    margin-left: 15%;
    font-size: 17px;
    background: lightgreen;
    border: solid grey;
    border-radius: 4px;
}

.box-grid-green {
    margin-left: 15%;
    font-size: 14px;
    background: lightgreen;
    border: solid grey;
    border-radius: 4px;
}

.box-grid-red {
    margin-left: 15%;
    font-size: 14px;
    background: lightcoral;
    border: solid grey;
    border-radius: 4px;
}

.box-grid-yellow {
    margin-left: 15%;
    font-size: 14px;
    background: lightyellow;
    border: solid grey;
    border-radius: 4px;
}

.grid-yellow {
    font-size: 17px;
    background: lightyellow;
    border: solid grey;
    border-radius: 4px;
}

.grid {
    margin-left: 15%;
    border: black;
    font-size: 17px;
}

.grid-black-empty {
    margin-left: 2.5%;
    border: solid black;
    width: 95%;
    margin-bottom: 1.5%;
    border-radius: 2px;
    text-decoration: none;
}

.gridContainer {
    margin-left: 2.5%;
    border: solid steelblue;
    width: 95%;
    margin-bottom: 1.5%;
    border-radius: 4px;
    background: lightsteelblue;
    text-decoration: none;
}

.gridContainer-box {
    margin-left: 2.5%;
    margin-top: 5%;
    border: solid steelblue;
    width: 95%;
    margin-bottom: 1.5%;
    border-radius: 4px;
    background: lightsteelblue;
    text-decoration: none;
}
.avatar {
    height: 40px;
    width: 40px;
}
.rowC {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
}
.colR{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 10px;
    margin-left: 0.2em;
    margin-top: 10%;
}
.colR-highlighted{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 10px;
    margin-left: 0.2em;
    border: solid crimson;
    border-spacing: 1px;
    margin-top: -10%;
    border-radius: 2px;
}
.mount{
    border: solid steelblue;
    width: 25px;
    height: 25px;
}

.mount-av{
    border: solid steelblue;
    background: green;
    width: 25px;
    height: 25px;
}

.mount-unav{
    border: solid steelblue;
    background: gray;
    width: 25px;
    height: 25px;
}

.box-small-av{
    margin-top: 15%;
    border: solid green;
    background: green;
    margin-left: 12.5%;
    width: 75%;
    font-size: 10px;
    font-weight: bold;
}
.box-large-av{
    border: solid green;
    background: green;
    margin-left: 5%;
    width: 90%;
    font-size: 12px;
    font-weight: bold;
}
.box-small-unav{
    border: solid gray;
    background: gray;
    margin-left: 12.5%;
    margin-top: 15%;
    height: 165%;
    width: 75%;
    font-size: 10px;
    font-weight: bold;
}
.box-large-unav{
    border: solid gray;
    background: gray;
    margin-left: 5%;
    width: 90%;
    font-size: 12px;
    font-weight: bold;
}
.no-box{
    font-size: 7px;
    margin-top: 88.5%;
}
.scanner-frame{
    -webkit-justify-content: center;
            justify-content: center;
    border: solid;
    horiz-align: right;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
}

.scannerContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.scannerWithMargin {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 3%;
}



.grid-gray {
    font-size: 14px;
    background: lightgray;
    border: solid grey;
    border-radius: 4px;
}

.grid {
    margin-left: 5%;
    border: black;
    font-size: 17px;
}
.gridContainer {
    margin-left: .5%;
    border: solid steelblue;
    width: 99%;
    margin-bottom: 1.5%;
    border-radius: 4px;
    background: lightsteelblue;
    text-decoration: none;
}
.smallFont {
    font-size: 11px;
    -webkit-justify-content: center;
            justify-content: center;
}
.text {
     font-size: 9px;
     margin-top: 5%;
}
.text-bottom {
    font-size: 12px;
    text-align: center;
    margin-left: 5%;
    margin-bottom: 5px;

}
.headline {
    font-size: 12px;
    font-weight: bold;
}
.printButton {
    font-size: 10px;
    padding: 5px 30px;
    border-radius: 2px;
    margin: 5px 0;
    cursor: pointer;
}
.buttonGroup {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
}


.returnedPackagesContainer h1 {
    font-size: medium;
    margin-bottom: 0.5em;
}

.packageListItemContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    background-color: lightyellow;
    border: solid;
    border-radius: 4px;
    border-color: darkgoldenrod;
    margin: 0.2em;
    margin-left: 1em;
    margin-right: 1em;
}


.loginBtn {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background: #000;
    color: #fff;
    border: none;
    padding: 5px 5px 5px 5px;
    border-radius: 2px;
    cursor: pointer;
    font-size: 11px;
    font-family: inherit;
    width: 228px;
    min-height: 10px;
    text-decoration: underline;
    -webkit-text-decoration-color: darkgreen;
            text-decoration-color: darkgreen;
}

.login-wrapper form {
    margin-top: 10px;
}

.login-wrapper input {
    width: 200px;
}


.grid-gray {
    font-size: 14px;
    background: lightgray;
    border: solid grey;
    border-radius: 4px;
}

.grid {
    margin-left: 5%;
    border: black;
    font-size: 17px;
}
.gridContainer {
    margin-left: .5%;
    border: solid steelblue;
    width: 99%;
    margin-bottom: 1.5%;
    border-radius: 4px;
    background: lightsteelblue;
    text-decoration: none;
}
.smallFont {
    font-size: 11px;
    -webkit-justify-content: center;
            justify-content: center;
}
.text {
     font-size: 9px;
     margin-top: 5%;
}
.text-bottom {
    font-size: 12px;
    text-align: center;
    margin-left: 5%;
    margin-bottom: 5px;

}
.headline {
    font-size: 12px;
    font-weight: bold;
}
.printButton {
    font-size: 10px;
    padding: 5px 30px;
    border-radius: 2px;
    margin: 5px 0;
    cursor: pointer;
}
.buttonGroup {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
}



.grid-gray {
    font-size: 14px;
    background: lightgray;
    border: solid grey;
    border-radius: 4px;
}

.grid {
    margin-left: 5%;
    border: black;
    font-size: 17px;
}
.gridContainer {
    margin-left: .5%;
    border: solid steelblue;
    width: 99%;
    margin-bottom: 1.5%;
    border-radius: 4px;
    background: lightsteelblue;
    text-decoration: none;
}
.smallFont {
    font-size: 11px;
    -webkit-justify-content: center;
            justify-content: center;
}
.text {
     font-size: 9px;
     margin-top: 5%;
}
.text-bottom {
    font-size: 12px;
    text-align: center;
    margin-left: 5%;
    margin-bottom: 5px;

}
.headline {
    font-size: 12px;
    font-weight: bold;
}
.printButton {
    font-size: 10px;
    padding: 5px 30px;
    border-radius: 2px;
    margin: 5px 0;
    cursor: pointer;
}
.buttonGroup {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
}

