.header {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    color: darkgreen;
    margin-bottom: 10px;
    font-size: 18px;
}

.logo {
    height: 32px;
    margin: 10px;
}
.containerHeadline {
    margin-left: 2.5%;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2%;
    color: steelblue;
}
.containerHeadlineSmall {
    margin-left: 2.5%;
    text-align: left;
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 2%;
    color: steelblue;
}
.container {
    max-width: 90%;
    margin: 5% auto;
    overflow: auto;
    border: 1px solid steelblue;
    padding: 10px;
    border-radius: 2px;
}
