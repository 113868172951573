
.returnedPackagesContainer h1 {
    font-size: medium;
    margin-bottom: 0.5em;
}

.packageListItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: lightyellow;
    border: solid;
    border-radius: 4px;
    border-color: darkgoldenrod;
    margin: 0.2em;
    margin-left: 1em;
    margin-right: 1em;
}

